<template>
	<div class="outer-wrapper">
		<div class="autocomplete-wrapper">
			<vue-google-autocomplete
				ref="autocomplete"
				id="address-autocomplete"
				classname="text-input"
				placeholder="Enter Delivery Address"
				:country="['us']"
				:defaultAddress="defaultAddress"
				@no-results-found="handleNoResults"
				@placechanged="getAddressData"
				@address-set="handleInputChange"
				@showLoading="clearErrorAndShowLoading"
			/>
			<button
				type="button"
				v-if="isLoggedIn"
				@click="toggleDropdown"
				class="autocomplete-icon-address-set"
			>
				<large-arrow-down />
			</button>
			<button
				type="button"
				@click="$refs.autocomplete.updateOnEnter()"
				class="autocomplete-icon-address-set"
				aria-label="Map my address and show all available restaurants."
			>
				GO
			</button>
			<div
				v-if="showDropdown"
				class="address-dropdown"
				v-click-outside="closeDropdown"
			>
				<ul>
					<li
						class="address-dropdown-item"
						v-for="address in savedAddresses"
						:key="address.id"
					>
						<button
							class="address-dropdown-button"
							type="button"
							@click="
								handleAddressSelect(
									address.addressSend,
									address.id
								)
							"
						>
							{{ address.label }} - {{ address.address }}
						</button>
					</li>
				</ul>
			</div>
		</div>
		<div class="error" v-if="error" v-html="error"></div>
		<div class="loading" v-if="showLoading">Loading...</div>
	</div>
</template>

<script>
import vClickOutside from "v-click-outside";

import VueGoogleAutocomplete from "./VueGoogleAutoComplete.vue";
import LargeArrowDown from "./icons/LargeArrowDown.vue";

export default {
	name: "InputAddress",
	components: {
		VueGoogleAutocomplete,
		LargeArrowDown
	},
	directives: {
		clickOutside: vClickOutside.directive
	},
	props: {
		savedAddresses: { type: Array },
		isLoggedIn: { type: Boolean },
		defaultAddress: { type: String }
	},
	data: function() {
		return {
			address: this.defaultAddress ? this.defaultAddress : "",
			error: "",
			showDropdown: false,
			showLoading: false,
			selectedProfileId: ""
		};
	},
	methods: {
		toggleDropdown() {
			this.showDropdown = !this.showDropdown;
		},
		closeDropdown() {
			this.showDropdown = false;
		},
		clearErrorAndShowLoading() {
			this.error = "";
			this.showLoading = true;
		},
		handleAddressSelect(address, addressId) {
			this.clearErrorAndShowLoading();
			this.showDropdown = false;
			this.selectedProfileId = addressId;
			this.$refs.autocomplete.update(address);
		},
		handleInputChange(addressData) {
			this.clearErrorAndShowLoading();
			this.address = addressData.address;
			addressData.deliveryProfileId = this.selectedProfileId;
			this.$emit("address-set", addressData);
		},
		getAddressData(addressData, placeResultData) {
			const {
				street_number,
				route,
				locality,
				administrative_area_level_1,
				postal_code,
				latitude,
				longitude
			} = addressData;
			this.clearErrorAndShowLoading();
			this.address = `${street_number} ${route} ${locality} ${administrative_area_level_1}`;
			this.handleInputChange({
				latitude: latitude,
				longitude: longitude,
				address: this.address,
				streetAddress: `${street_number} ${route}`,
				city: `${locality}`,
				state: `${administrative_area_level_1}`,
				zip: `${postal_code}`
			});
		},
		handleNoResults(obj) {
			this.showLoading = false;
			if (obj && obj.name) {
				this.error = `No results found for <strong>${obj.name}</strong>`;
			} else {
				this.error = `No results found.`;
			}
			this.$emit("noResults");
		}
	}
};
</script>

<style lang="scss" scoped>
.outer-wrapper {
	position: relative;
}

.address-dropdown {
	font-size: 16px;
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	background-color: $white;
	box-shadow: $box-shadow;
	padding: 20px;
	z-index: 10;
}

.address-dropdown-item:not(:last-of-type) {
	margin-bottom: 10px;
}

.address-dropdown-button {
	@include transition(color);
	font-weight: 700;
	color: $royal;
	width: 100%;
	text-align: left;

	&:hover,
	&:focus {
		color: $tomato;
	}
}

.autocomplete-wrapper {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	color: #000;
	font-family: montserrat_bold, sans-serif;
	font-size: 25px;
}

.text-input {
	@include text-input;
	padding: 5px 0px 5px 5px;
}

// .autocomplete-icon,
// .autocomplete-icon-address-set {
// 	position: absolute;
// }

.autocomplete-icon {
	color: $tomato;
	right: 10px;
	top: 50%;
	transform: translate(0, -50%);
}

.autocomplete-icon-address-set {
	background-color: $tomato;
	color: $white;
	top: 0;
	right: 0;
	bottom: 0;
	width: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #d3d3d3;
}

.loading {
	color: #ffd618;
	font-size: 17px;
	margin-top: 4px;

	@media (min-width: $mobileLandscape) {
		position: absolute;
		top: 100%;
		left: 0;
	}
}
.error {
	color: #ffd618;
	font-size: 17px;
	margin-top: 4px;

	@media (min-width: $mobileLandscape) {
		position: absolute;
		top: 100%;
		left: 0;
	}
}
</style>